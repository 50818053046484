import { useEffect, useState } from 'react'
import axios from 'axios'

import Search from '../../particles/Search'
import Title from '../../particles/Title'
import Publication from './components/Publication'
import NoResult from '../../particles/NoResult'

import '../../../assets/styles/css/pages/publications/publications.css'
import Preloader from '../../particles/Preloader'
import { API } from '../../../shared/api/api'

function Publications() {
  const [publications, setPublications] = useState([])
  const [preloader, setPreloader] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [sorting, setSorting] = useState('asc') // desc
  
  useEffect(() => {
    const getPublications = () => {
      API.getPublications()
        .then(content => setPublications( content.data.publications ))
        .catch(err => console.error(err))
        .finally(() => setPreloader(false))
    }
    
    getPublications()
  }, [])

  function checkEntries(array, string) {
    return array.join().toLowerCase().includes(string.toLowerCase())
  }

  const publicationsFinder = query => setSearchQuery(query)

  const publicationsSorting = query => setSorting(query)

  return (
    <main>
      <Title text='Publications' />
      <br />
      <Search
        onClickHandler={publicationsFinder}
        onChangeHandler={publicationsFinder}
        onSort={publicationsSorting}
        sorting={sorting}
        placeholder='Search publications by author' />

      {
        preloader ? (
          <>
            <Preloader height='85px' width='100%' />
            <Preloader height='85px' width='100%' />
            <Preloader height='85px' width='100%' />
            <Preloader height='85px' width='100%' />
          </>
        ) : (
          <>
            <article className='publications'>
              {
                publications
                  .sort((a, b) => {
                    if (sorting === 'desc')
                      return a.year - b.year
                    else
                      return b.year - a.year
                  })
                  .map((publication, index) => {
                  return checkEntries([...publication.authors, publication.title], searchQuery) ?
                      <Publication 
                        key={index}
                        index={index}
                        publication={publication} />
                      :
                      ''
                })
              }
            </article>
            {
              !publications.some((publication) => checkEntries(publication.authors, searchQuery)) ?
                <NoResult text='Publications not found' />
                :
                ''
            }
                </>
              )
            }
    </main>
  )
}

export default Publications