import { useState } from 'react'

import images from '../../assets/images/imgs'

import '../../assets/styles/css/particles/search.css'

function Search({ onChangeHandler, onClickHandler, placeholder, onSort, sorting }) {
  const [inputValue, setInputText] = useState('')

  function onChangeEvent(event) {
    const text = event.target.value
    onChangeHandler(text)

    setInputText(text)
  }

  function onClickEvent() {
    onClickHandler(inputValue)
  }

  function clearSearch() {
    setInputText('')
    onClickHandler('')
  }

  return <div className='page-search'>
    {
      sorting ? (
        <button
          onClick={() => onSort(sorting === 'desc' ? 'asc' : 'desc')}
          className={`elements-sorting ${sorting === 'desc' ? 'active' : ''}`}>
          <img src={images.sorting} />
        </button>
      ) : ''
    }

    <input
      value={inputValue}
      onChange={onChangeEvent}
      placeholder={placeholder}
      type='text' />

    <div className='clear'>
      <span
        onClick={clearSearch}
        className={inputValue ? 'active' : ''}>
        &times;
      </span>
    </div>

    <button onClick={onClickEvent}>Find</button>
  </div>
}

export default Search
