import Parser from 'html-react-parser'

import images from '../../../../assets/images/imgs'

function ProjectItem({ project }) {
  const navigate = link => window.open(link)

  return (
    <section className='project'>
      <h4 className='title' onClick={() => navigate(project.link)}>
        {project.title}

        <img
          src={images.link}
          alt="link" />
      </h4>

      <img
        className={project.image === '' ? 'default' : ''}
        src={project.image ? project.image : images.preview}
        alt="project" />

      <div className='devs'>
        {
          project.developers.map(dev => {
            return <span className='dev'>{dev}</span>
          })
        }
      </div>

      <span className='content'>
        {Parser(project.content)}
      </span>

    </section>
  )
}

export default ProjectItem
