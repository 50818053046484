import images from "../../assets/images/imgs";

export const oldMebmers = [
  {
    _id: '1',
    name: 'Prof. Chaogui Kang',
    positions: ["Visiting scholar","Associate Professor At Wuhan University"],
    description :"",
    image: images.Chaopgui2
  },
  {
    _id: '2',
    name: 'Maarten Vanhoof',
    positions: ["Visiting scholar", "PhD student at Newcastle University", "Associated researcher at Orange Labs"],
    description :"",
    image: images.maarten31
  },
  {
    _id: '3',
    name: 'Enwei Zhou',
    positions: ["Visiting scholar","PhD student at Singhua University, China"],
    description :"",
    image: images.user1,
  },
  {
    _id: '4',
    name: 'Philipp Kats',
    positions: ["Part-time researcher","Data scientist at StreetEasy"],
    description :"",
    image: images.philip,
  },
  {
    _id: '5',
    name: 'Shivam Kumar Pathak',
    positions: ["Graduate research assistant"],
    description :"",
    image: images.Shivam,
  },
  {
    _id: '6',
    name: 'Chinmay Singhal',
    positions: ["Graduate research assistant"],
    description :"",
    image: images.Chinmay,
  },
]

