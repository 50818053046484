import axios from 'axios'
import { useEffect, useState } from 'react'

import ProjectItem from './components/ProjectItem'
import Title from '../../particles/Title'
import NoResult from '../../particles/NoResult'
import Preloader from '../../particles/Preloader'

import '../../../assets/styles/css/pages/projects/projects.css'
import { API } from '../../../shared/api/api'

function Projects() {
  const [projects, setProjects] = useState([])
  const [preloader, setPreloader] = useState(true)

  useEffect(() => {
    const getProjects = () => {
      API.getProjects()
        .then(content => setProjects(content.data.projects))
        .catch(err => console.error(err))
        .finally(() => setPreloader(false))
    }

    getProjects()
  }, [])

  return <main>
  <Title text='Projects' />

    {
      preloader ? (
        <article className='projects preloader-projects'>
          <Preloader height='300px' width='35%' />
          <Preloader height='300px' width='35%' />
          <Preloader height='300px' width='35%' />
          <Preloader height='300px' width='35%' />
        </article>
      ) : (
        projects.length > 0 ?
        (
          <article className='projects'>
            {
              projects.map((project, i) => <ProjectItem key={i} project={project} />)
            }
          </article>
        ) : (
          <>
            <br />
            <NoResult text='Can not find projects'/>
          </>
        )
      )
    }
  </main>
}

export default Projects
