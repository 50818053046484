import city from '../images/pages/home/city.svg'
import cityCompressed from '../images/pages/home/cityCompressed.jpeg'
import logo from '../images/common/logo2.svg'

import support from '../images/pages/home/support.svg'
import smartCity from '../images/pages/home/smartCity.svg'
import team from '../images/pages/home/team.svg'
import papers from '../images/pages/home/papers.svg'
import study from '../images/pages/home/study.svg'

import email from '../images/particals/footer/email.svg'
import location from '../images/particals/footer/location.svg'
import phone from '../images/particals/footer/phone.svg'

import car from '../images/pages/collaborations/car.svg'
import hierarchical from '../images/pages/collaborations/hierarchical.svg'
import taxi from '../images/pages/collaborations/taxi.svg'

import preview from '../images/pages/blogs/preview.svg'
import navigationArrow from '../images/pages/blogs/components/arrow.svg'

import success from '../images/particals/feedback/success.svg'

import menu from '../images/particals/header/menu.svg'
import close from '../images/particals/header/close.svg'

import sorting from '../images/particals/search/sorting.svg'

import link from '../images/pages/projects/link.svg'

import journal from '../images/pages/publications/journal.svg'

// prev team 
import philip from '../images/pages/team/philipp2.png'
import Chinmay from '../images/pages/team/Chinmay.jpg'
import Chaopgui2 from '../images/pages/team/Chaopgui2.png'
import user1 from '../images/pages/team/picture-for-web.png'
import Shivam from '../images/pages/team/Shivam.png'
import maarten31 from '../images/pages/team/maarten31.jpg'

const images = {
  link,
  journal,
  sorting,
  philip,
  Chinmay,
  Chaopgui2,
  maarten31,
  Shivam,
  user1,
  city,
  cityCompressed,
  logo,
  support,
  smartCity,
  team,
  papers,
  study,
  email,
  location,
  phone,
  car,
  hierarchical,
  taxi,
  preview,
  navigationArrow,
  success,
  menu,
  close
}

export default images