import axios from "axios"

const getWebsiteUrl = () => {
  const currentOrigin = window.location.origin

  return currentOrigin.includes('localhost')
    ? `http://localhost:5005`
    : process.env.REACT_APP_SERVER_DATA
}


const urlPath = getWebsiteUrl()

const path = path => urlPath + path

// PUBCLICATIONS
const getPublications = () => 
  axios.get( path('/publication/get') )


// PROJECTS
const getProjects = () => 
  axios.get( path('/project/get') )

export const API = {
  getPublications,
  getProjects
}
